import { useFormik } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { toastFormikErrors, gtag } from '@utils';
import register from '@api/auth';
import Switch from '@components/Switch';
import SuperButton from '@components/SuperButton';
import PasswordInput from '@components/PasswordInput';
import { memo, useEffect } from 'react';
import browserSignature from 'browser-signature/dist/storage';

export default memo(({ onRegister, setCurrentForm, onRegisterRedirectTo }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectTo = onRegisterRedirectTo || searchParams.get('to');

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      accept: false,
      fingerprint: browserSignature(),
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Email wymagany').email('Niewłaściwy email.'),
      password: Yup.string().required('Hasło wymagane').min(6, 'Hasło musi mieć 6 lub więcej znaków.'),
      accept: Yup.boolean().required().oneOf([true], 'Musisz zaaceptować regulamin.'),
    }),
    onSubmit: (values) => {
      register(values).then(({ data }) => {
        if (data.error === false) {
          formik.resetForm();
          toast.success(data.message, {
            autoClose: 10000,
          });

          gtag('event', 'sign_up', {
            method: 'Standard',
          });

          if (typeof onRegister === 'function') onRegister(values, setCurrentForm);

          if (redirectTo) return navigate(redirectTo);

          return setCurrentForm('LOGIN');
        }
        return toast.error(data.message);
      }).catch((error) => {
        toast.error(error.response.data.message);
      }).finally(() => {
        formik.setSubmitting(false);
      });
    },
  });

  useEffect(() => {
    /* global google */
    google.accounts.id.renderButton(document.getElementById('signUpDivModal'), {
      type: 'standard',
      shape: 'pill',
      text: 'signup_with',
      theme: 'outline',
      size: 'large',
      width: '320',
    });
  }, []);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">Email:</label>
          <input type="email" id="email" className="form-control" {...formik.getFieldProps('email')} />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">Hasło:</label>

          <PasswordInput
            id="password"
            className="form-control"
            placeholder="+6 znaków"
            skipDemoMode
            {...formik.getFieldProps('password')}
          />
        </div>
        <div className="d-flex mb-3">
          <Switch
            onChange={(checked) => formik.setFieldValue('accept', checked)}
            name="accept"
            checked={formik.values.accept}
            size="small"
            className="mx-2 mt-1"
          />

          <label
            htmlFor="accept"
            className="form-label"
            onClick={() => formik.setFieldValue('accept', !formik.values.accept)}
          >
            Znam i akceptuję
            regulamin oraz politykę prywatności.
          </label>
        </div>

        <SuperButton
          type="submit"
          isLoading={formik.isSubmitting}
          disabled={!formik.values.accept || !formik.values.email || !formik.values.password || formik.isSubmitting}
          onClick={() => toastFormikErrors(formik.errors)}
          className="btn btn-primary btn-block my-4"
        >
          Zarejestruj
        </SuperButton>
      </form>
      <div className="mb-4 d-flex justify-content-center">
        <div id="signUpDivModal" />
      </div>
    </div>
  );
});
