import { createContext, useContext } from 'react';
// eslint-disable-next-line import/no-cycle
import { usePersistedReducer } from '../hooks';
import reducer from './reducer';

const initialValues = {
  googlePlayApp: false,
  user: {},
};

const StoreContext = createContext(initialValues);

export function StoreProvider({ children }) {
  const [stats, dispatch] = usePersistedReducer(reducer, initialValues);

  return (
  // eslint-disable-next-line react/jsx-no-constructed-context-values
    <StoreContext.Provider value={{ ...stats, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
}

export default StoreContext;

// Store React hook

export function useStore() {
  return useContext(StoreContext);
}
