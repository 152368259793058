import { Link, NavLink } from 'react-router-dom';
import { memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarsStaggered } from '@fortawesome/free-solid-svg-icons';
import { IconLayoutDashboard, IconLogout, IconUser } from '@tabler/icons-react';
import { useSettings } from '../hooks';
import { useUser } from '../hooks/auth';
import Logo from './Logo';
import SectionLoading from './SectionLoading';
import AvatarPalceholder from './AvatarPalceholder';
import Avatar from './Avatar';
import TablerIcon from './TablerIcon';

export default memo(() => {
  const { isLoading, settings } = useSettings();
  const { isAuthenticated, user, isAdmin } = useUser();

  if (isLoading || !Object.keys(settings).length) {
    return <SectionLoading center />;
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark navbar-override">
      <div className="container">
        <Logo settings={settings} className="navbar-brand" to="/" />

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
          <FontAwesomeIcon icon={faBarsStaggered} />
        </button>
        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink className="nav-link" to="/playground">Zadania</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/pricing">Pakiety</NavLink>
            </li>
          </ul>

          <ul className="nav navbar-nav navbar-right">
            {isAuthenticated ? (
              <div className="btn-group avatar-dropdown">
                <button type="button" className="btn text-white dropdown-toggle d-flex align-items-center gap-2" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
                  { user.avatar ? <Avatar src={user.avatar} size={45} /> : <AvatarPalceholder username={user.username} size={45} />}
                  {' '}
                  Cześć,
                  {' '}
                  {user.name ? user.name.split(' ')[0] : user.username.split('@')[0]}
                </button>
                <div className="dropdown-menu dropdown-menu-lg-end dropdown-menu-dark">
                  {isAdmin && (
                  <Link className="dropdown-item" to="/admin/">
                    <TablerIcon icon={IconLayoutDashboard} />
                    {' '}
                    Dashboard
                  </Link>
                  )}
                  <Link to="/account/settings" className="dropdown-item">
                    <TablerIcon icon={IconUser} />
                    {' '}
                    Profil
                  </Link>
                  <hr className="dropdown-divider" />
                  <Link to="/logout" className="dropdown-item">
                    <TablerIcon icon={IconLogout} />
                    {' '}
                    Wyloguj
                  </Link>
                </div>
              </div>
            ) : (
              <>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/login">Zaloguj</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/register">Zarejestruj</NavLink>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
});
