import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { toastFormikErrors } from '../../../utils';
import { editPlan } from '../../../api/admin';
import SuperButton from '../../SuperButton';
import Switch from '../../Switch';
import { useDashboardPlan, useSettings } from '../../../hooks';
import SectionLoading from '../../SectionLoading';

export default function EditPlanForm({ close, planId }) {
  const queryClient = useQueryClient();
  const { settings } = useSettings();
  const {
    isLoading, plan,
  } = useDashboardPlan(planId);

  // fixing null values
  plan.description = plan?.description == null ? '' : plan?.description;
  plan.plan_name = plan?.plan_name == null ? '' : plan?.plan_name;
  plan.features = plan?.features == null ? '' : plan?.features;

  const formik = useFormik({
    initialValues: {
      name: plan.name,
      description: plan.description,
      plan_name: plan.plan_name,
      price: plan.price,
      old_price: plan.old_price,
      is_popular: plan.is_popular,
      is_free: plan.is_free,
      status: plan.status,
      pdfs: plan.pdfs,
      pdf_size: plan.pdf_size,
      questions: plan.questions,
      features: plan.features,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      description: Yup.string().nullable(),
      plan_name: Yup.string().nullable(),
      price: Yup.number('Price must be a number.'),
      old_price: Yup.number('Price must be a number.'),
      is_popular: Yup.boolean('Popular field must be boolean'),
      is_free: Yup.boolean('Free Plan field must be boolean'),
      status: Yup.boolean('Status field must be boolean'),
      pdfs: Yup.number('Pdfs field must be a number.'),
      pdf_size: Yup.number('Pdf Size field must be a number.'),
      questions: Yup.number('Questions field must be a number.'),
      features: Yup.string().nullable(),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      if (!values.is_free && values.price === 0) return toast.warning("The plan price can't be 0!");

      editPlan(plan.id, values).then((data) => {
        if (data.errors === false) {
          formik.resetForm();
          toast.success(data.message);
          queryClient.invalidateQueries('admin.plans');
          close();
        } else {
          return toast.error(data.message);
        }
      }).catch((error) => {
        toast.error(error.response.data.message);
      }).finally(() => {
        formik.setSubmitting(false);
      });
    },
  });

  if (isLoading && !Object.keys(plan).length) {
    return <SectionLoading center />;
  }

  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off">
      <div className="mb-4">
        <label htmlFor="name">Name:</label>
        <input
          type="text"
          className="form-control"
          placeholder="e.g. Premium"
          id="name"
          {...formik.getFieldProps('name')}
        />
      </div>

      <div className="mb-4">
        <label htmlFor="description">Heading (optional):</label>
        <input
          type="text"
          className="form-control"
          id="slug"
          placeholder="e.g. The Best for Startups"
          {...formik.getFieldProps('description')}
        />
      </div>

      <div className="mb-4">
        <label htmlFor="plan_name">Plan name:</label>
        <input
          type="text"
          className="form-control"
          id="plan_name"
          placeholder="plan_name"
          {...formik.getFieldProps('plan_name')}
        />
      </div>

      <label htmlFor="price">
        Price (in
        {settings?.CURRENCY}
        ):
      </label>
      <div className="input-group mb-4">
        <span className="input-group-text">{settings?.CURRENCY_SYMBOL}</span>
        <input
          type="number"
          className="form-control"
          placeholder="e.g. 5.99"
          id="price"
          {...formik.getFieldProps('price')}
          min={0}
          step={0.01}
          disabled={formik.values.is_free}
        />
      </div>

      <label htmlFor="old_price">
        Old price (in
        {settings?.CURRENCY}
        ):
      </label>
      <div className="input-group mb-4">
        <span className="input-group-text">{settings?.CURRENCY_SYMBOL}</span>
        <input
          type="number"
          className="form-control"
          placeholder="e.g. 5.99"
          id="old_price"
          {...formik.getFieldProps('old_price')}
          min={0}
          step={0.01}
          disabled={formik.values.is_free}
        />
      </div>

      <div className="d-flex mb-3">
        <Switch name="is_free" checked={!!formik.values.is_free} size="small" className="mx-2 mt-1" disabled />

        <label htmlFor="is_free" className="form-label">Free plan!</label>
      </div>

      <div className="d-flex mb-3">
        <Switch
          onChange={(checked) => formik.setFieldValue('is_popular', checked)}
          name="is-popular"
          checked={!!formik.values.is_popular}
          size="small"
          className="mx-2 mt-1"
        />

        <label
          htmlFor="is_popular"
          className="form-label"
          onClick={() => formik.setFieldValue('is_popular', !formik.values.is_popular)}
        >
          set as popular (show
          popular mark)!
        </label>
      </div>

      <div className="d-flex mb-3">
        <Switch
          onChange={(checked) => formik.setFieldValue('status', checked)}
          name="status"
          checked={!!formik.values.status}
          size="small"
          className="mx-2 mt-1"
        />

        <label
          htmlFor="status"
          className="form-label"
          onClick={() => formik.setFieldValue('status', !formik.values.status)}
        >
          Status
        </label>
      </div>

      <hr />
      <div className="mb-4">
        <label htmlFor="pdfs">
          Max PDFs
          <small>(0 = unlimited)</small>
          :
        </label>
        <input
          type="number"
          className="form-control"
          placeholder="e.g. 10"
          id="pdfs"
          {...formik.getFieldProps('pdfs')}
          min={0}
          onChange={(e) => formik.setFieldValue('pdfs', parseInt(e.target.value))}
        />
      </div>

      <div className="mb-4">
        <label htmlFor="pdf_size">
          Max PDF size (in MB)
          <small>(0 = unlimited)</small>
          :
        </label>
        <input
          type="number"
          className="form-control"
          placeholder="e.g. 10"
          id="pdf_size"
          {...formik.getFieldProps('pdf_size')}
          min={0}
          step={0.1}
        />
      </div>

      <div className="mb-4">
        <label htmlFor="questions">
          Max questions
          <small>(0 = unlimited)</small>
          :
        </label>
        <input
          type="number"
          className="form-control"
          placeholder="e.g. 10"
          id="questions"
          {...formik.getFieldProps('questions')}
          min={0}
          onChange={(e) => formik.setFieldValue('questions', parseInt(e.target.value))}
        />
      </div>

      <hr />

      <div className="mb-4">
        <label htmlFor="features">
          More Plan Features
          <small><i>(feature per line)</i></small>
          :
        </label>
        <textarea
          className="form-control"
          placeholder="e.g. 24/7 Support."
          id="features"
          {...formik.getFieldProps('features')}
          rows={3}
          onChange={(e) => formik.setFieldValue('features', e.target.value)}
        />
      </div>

      <SuperButton
        isLoading={formik.isSubmitting}
        type="submit"
        className="btn btn-primary btn-lg btn-block"
        onClick={() => toastFormikErrors(formik.errors)}
      >
        Update
      </SuperButton>
    </form>
  );
}
