import {
  faComment, faDollarSign, faGem, faMobileAndroidAlt, faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';
import { useDashboardSettings } from '../../hooks';
import SectionLoading from '../../components/SectionLoading';
import { useDashboardAnalytics } from '../../hooks/admin';

export default function AdminDashboardPage() {
  const { isLoading: isSettingLoading, settings } = useDashboardSettings();
  const { isLoading: isAnalyticsLoading, analytics } = useDashboardAnalytics();

  if (isSettingLoading || isAnalyticsLoading || !Object.keys(analytics).length) {
    return <SectionLoading center />;
  }

  const isWeekend = (dayName) => {
    const weekendDays = ['Saturday', 'Sunday'];
    return weekendDays.includes(dayName);
  };

  const ratio = (all, part) => Math.round((part / all) * 100);

  return (
    <>
      <h1 className="mb-5">Dashboard</h1>

      <div className="row row-cols-1 row-cols-md-2 row-cols-xl-2 row-cols-xxl-3 g-3 mb-3">
        <div className="col">
          <div className="infobox bg-success text-light p-3 d-flex">
            <div className="d-flex justify-content-center align-items-center">
              <FontAwesomeIcon icon={faDollarSign} size="4x" className="infobox-icon" />
            </div>
            <div className="p-3 d-flex flex-column flex-grow-1">
              <span className="infobox-title h2">
                {analytics.today_revenue}
                {' '}
                {settings.CURRENCY_SYMBOL}
              </span>
              <span className="infobox-desc">Today Income</span>
            </div>
          </div>
        </div>

        <div className="col">
          <div className="infobox bg-primary text-light p-3 d-flex">
            <div className="d-flex justify-content-center align-items-center">
              <FontAwesomeIcon icon={faUsers} size="4x" className="infobox-icon" />
            </div>
            <div className="p-3 d-flex flex-column flex-grow-1">
              <span className="infobox-title h2">{analytics.today_users}</span>
              <span className="infobox-desc">Today Customers</span>
            </div>
          </div>
        </div>

        <div className="col">
          <div className="infobox bg-success text-light p-3 d-flex">
            <div className="d-flex justify-content-center align-items-center">
              <FontAwesomeIcon icon={faDollarSign} size="4x" className="infobox-icon" />
            </div>
            <div className="p-3 d-flex flex-column flex-grow-1">
              <span className="infobox-title h2">
                {analytics.total_revenue}
                {' '}
                {settings.CURRENCY_SYMBOL}
              </span>
              <span className="infobox-desc">Total Income</span>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="infobox bg-primary text-light p-3 d-flex">
            <div className="d-flex justify-content-center align-items-center">
              <FontAwesomeIcon icon={faUsers} size="4x" className="infobox-icon" />
            </div>
            <div className="p-3 d-flex flex-column flex-grow-1">
              <span className="infobox-title h2">{analytics.customers_count}</span>
              <span className="infobox-desc">Total Customers</span>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="infobox bg-info text-light p-3 d-flex">
            <div className="d-flex justify-content-center align-items-center">
              <FontAwesomeIcon icon={faComment} flip="horizontal" className="infobox-icon" />
            </div>
            <div className="p-3 d-flex flex-column flex-grow-1">
              <span className="infobox-title h2">{analytics.documents_count}</span>
              <span className="infobox-desc">Chat Rooms</span>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="infobox bg-warning p-3 d-flex">
            <div className="d-flex justify-content-center align-items-center">
              <FontAwesomeIcon icon={faGem} size="4x" className="infobox-icon" />
            </div>
            <div className="p-3 d-flex flex-column flex-grow-1">
              <span className="infobox-title h2">{analytics.paid_orders_count}</span>
              <span className="infobox-desc">Total paid orders</span>
            </div>
          </div>
        </div>

        <div className="col">
          <div className="infobox bg-secondary text-light p-3 d-flex">
            <div className="d-flex justify-content-center align-items-center">
              <FontAwesomeIcon icon={faMobileAndroidAlt} size="4x" className="infobox-icon" />
            </div>
            <div className="p-3 d-flex flex-column flex-grow-1">
              <span className="infobox-title h2">{analytics.app_today_users}</span>
              <span className="infobox-desc">Today App Customers</span>
            </div>
          </div>
        </div>

        <div className="col">
          <div className="infobox bg-secondary text-light p-3 d-flex">
            <div className="d-flex justify-content-center align-items-center">
              <FontAwesomeIcon icon={faMobileAndroidAlt} size="4x" className="infobox-icon" />
            </div>
            <div className="p-3 d-flex flex-column flex-grow-1">
              <span className="infobox-title h2">
                {analytics.app_today_revenue}
                {' '}
                {settings.CURRENCY_SYMBOL}
              </span>
              <span className="infobox-desc">Today App Income</span>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-12">
          <Card>
            <Card.Header className="card-title">Analytics data</Card.Header>
            <Card.Body className="overflow-auto">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Day</th>
                    <th>Income</th>
                    <th>Orders (% returning, % app)</th>
                    <th>Chats (% returning)</th>
                    <th>Users (% verified, % google, % app)</th>
                  </tr>
                </thead>
                <tbody>
                  {analytics.data.map((user, i) => (
                    <tr className={isWeekend(user.day_name) ? 'table-warning' : ''} key={i}>
                      <td>
                        {user.day}
                      </td>
                      <td>
                        <b>
                          {user.total_amount}
                          {' '}
                          zł
                        </b>
                      </td>
                      <td>
                        <b>{user.order_count}</b>
                        {' '}
                        (
                        {ratio(user.order_count, user.orders_different_day)}
                        %,
                        {' '}
                        {ratio(user.order_count, user.order_count_app)}
                        %)
                      </td>
                      <td>
                        <b>{user.chats_count}</b>
                        {' '}
                        (
                        {ratio(user.chats_count, user.chats_different_day)}
                        %)
                      </td>
                      <td>
                        <b>{user.total_users}</b>
                        {' '}
                        (
                        {ratio(user.total_users, user.verified_users)}
                        %,
                        {' '}
                        {ratio(user.total_users, user.google_users)}
                        %,
                        {' '}
                        {ratio(user.total_users, user.app_users)}
                        %)
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}
