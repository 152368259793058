import axios from 'axios';
// import { useLocalStore } from '@hooks/index';

const axiosApi = axios.create({
  baseURL: `${window.location.origin}/api/v1`,
});

axiosApi.interceptors.request.use(async (config) => {
  let token = null;
  try {
    token = JSON.parse(localStorage.getItem('tk'));
  } catch (err) {
    token = '';
  }

  // eslint-disable-next-line no-param-reassign
  if (token) config.headers.Authorization = `Bearer ${token}`;

  return config;
});

axiosApi.interceptors.request.use(async (config) => {
  let isGooglePlayApp;
  try {
    const data = JSON.parse(localStorage.getItem('storedStats'));
    isGooglePlayApp = data.googlePlayApp;
  } catch (err) {
    isGooglePlayApp = false;
  }
  // eslint-disable-next-line no-param-reassign
  config.headers.googlePlayApp = isGooglePlayApp;

  return config;
});

// Check if login is required
axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle an error response
    if (error.response.status === 401) {
      localStorage.removeItem('tk');
      if (window.location.href.includes('/admin') || window.location.href.includes('/account')) {
        // eslint-disable-next-line no-return-assign
        return window.location.href = `/login?to=${window.location.pathname}`;
      }
    }
    return Promise.reject(error);
  },
);

export default axiosApi;
