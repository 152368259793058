import przelewy24img from '@images/przelewy24/Przelewy24.png';

export default function Przelewy24Methods({ ...rest }) {
  const imageStyle = {
    maxWidth: '900px',
    width: '100%',
    height: 'auto',
  };

  return <img src={przelewy24img} alt="Przelewy24" {...rest} style={imageStyle} />;
}
